<template>
    <super-admin-auth-layout>
        <template #default>
            <div @click="errorMessage=''" class="mt-4 border-r-2">
                <div class="text-center pos-r">
                    <h5 class="mb-1 pos-r"><span class="font-inter-regular">Login to</span> <span class="fs-lg-6">Bank e Auctions India.com Super Admin</span><span class="bullet"></span></h5>
                    <p class="font-inter-light fs--1">Please enter the details below to login</p>
                </div>
                <s-form @submit.prevent="loginClicked">
                    <!--                <label class="label">Email</label>-->
                    <validated-input label="Email" placeholder="Enter your email ID" name="Email ID" type="email"
                                     class="mb-3 text-secondary c-form-input"
                                     v-model="model.email"
                                     :disabled="loading" :rules="rules.email"/>
                    <validated-input label="Password" placeholder="Enter Password" type="password"
                                     v-model="model.password" class="text-secondary mb-1 c-form-input"
                                     :disabled="loading" :rules="rules.password"/>
                    <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                    <p @click="$router.push('/forgot-password/')"
                       class="mb-4 text-right font-inter-medium link text-secondary">Forgot Password ?</p>
                    <div class="text-center">
                        <btn class="pl-5 pr-5 py-3 font-inter-medium bg-secondary-600" block text="Login" loading-text="Validating..." size=""
                             :disabled="loading" :loading="loading"/>
                    </div>
                </s-form>
            </div>

        </template>
    </super-admin-auth-layout>
</template>

<script>
import SuperAdminAuthLayout from '@/views/auth/super-admin-auth/SuperAdminAuthPageLayout';
import urls from '@/data/urls';

export default {
    name       : 'SuperAdminLogin',
    components : { SuperAdminAuthLayout },
    data () {
        return {
            loading      : false,
            loginUrl     : urls.auth.login,
            errorMessage : '',
            model        : {
                email    : '',
                password : ''
            },
            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    }
};
</script>

<style scoped>

</style>
